<template>
  <div class="container">


    <!-- <div class="content">
      <i
        class="audioi iconfont icon-bofang-list"
        v-if="!audioPlayFlag"
        @click="handlePlay"
      ></i>
      <i
        class="audioi iconfont icon-zantingtingzhi"
        v-else
        @click="handlePause"
      ></i>
      <p>正在播放{{ title }}简介</p>
    </div>
    <i class="quxiao iconfont icon-quxiao" @click="cancelPlay"></i> -->
    <div class="content">
      <p>正在播放{{ title }}简介</p>
      <i class="quxiao iconfont icon-quxiao" @click="cancelPlay"></i>
    </div>
    <audio controls ref="myAudio" :src="audiosrc" preload @ended="changeAudioType"></audio>
    <!-- <div class="sidebar">
      <i class="a iconfont icon-yinpin" v-if="audioPlayFlag"     @click="handlePause"></i>
      <i class="a iconfont icon-yinliangjingyinhei2x" v-else  @click="handlePlay"></i>
    </div> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["defaultIndex","audioFlag"]),
    myAudio() {
      // return document.getElementById("my-audio");
      return this.$refs.myAudio;
    },

    ...mapState(["audiosrc", "audioFlag", "audioPlayFlag", "title"]),
  },
  methods: {
    handlePlay() {
      this.$store.commit("changeAudioPlayFlag", true);
      this.myAudio.play();
    },
    handlePause() {
      this.$store.commit("changeAudioPlayFlag", false);
      this.myAudio.pause();
    },
    cancelPlay() {
      this.$store.commit("changeAudioFlag", false);
      this.$store.commit("changeAudioPlayFlag", false);
    },
    changeAudioType () {
      this.$store.commit('changeAudioPlayFlag',false)
      this.$store.commit('changeAudioType','1')
    }
  },
  watch: {
    audioPlayFlag() {
      console.log("音频播放");
      if (this.audioPlayFlag) {
        this.myAudio.play();
      } else {
        this.myAudio.pause();
      }
    },
  },
  mounted() {
    this.myAudio.play();
    this.$store.commit("changeAudioPlayFlag", true);
  },
};
</script>


<style scoped lang="less">
.container {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #F1F3F4;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
  position: relative;
  .content {
    display: flex;
    justify-content: space-between;
    p {
      color: #333;
      font-size: 10px;
    }
  }
  audio {
    width: 100%;
    height: 30px;
    margin-top: 5px;
  }
  .audioi {
    color: #a07416;
  }
  .quxiao {
    font-size: 14px;
    color: #333;
  }
  .sidebar {
    position: absolute;
    right: 20px;
    top: 140px;
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 22px;
      color: #fff;
    }
  }
}
</style>

<style >
</style>
