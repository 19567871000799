<template>
  <div class="wrapper">
    <div class="box1">
      <div class="num" v-if="pointDetail.flag">{{ pointDetail.id }}</div>
      <div class="img">
        <img :src="pointDetail.imgsrc" alt="" />
      </div>
      <div class="i2">
        <div class="title">{{ pointDetail.title }}</div>
        <div class="btn" @click="openGaode">
          <i class="iconfont icon-daohang"></i>
          <span>去这里</span>
        </div>

        <!-- <div class="dis">距此{{pointDetail.distance}}</div> -->
      </div>
      <div class="i3" v-show="pointDetail.audiosrc === false">
        <div class="i3-c">
          <div class="i3-box">
            <div class="a">
              <i
                  class="iconfont icon-zantingtingzhi"
                  v-if="audioPlayFlag"
                  @click="handlePause"
              ></i>
              <i
                  class="iconfont icon-bofang-list"
                  v-else
                  @click="handlePlay"
              ></i>
            </div>
          </div>

          <audio id="my-audio" ref="myAudio"></audio>
        </div>
        <div class="time" style="display:none;"></div>
      </div>
      <div class="la"></div>
    </div>
    <div class="jinqujieshao">
      <div class="title">
        <p></p>
        属吕梁山脉芦芽山西支脉，背靠芦芽山，面朝鹅城（县城），是省城太原与芦芽山国家森林公园过境旅游线路上的一大名胜景区
      </div>
      <div class="detail">

        <!--{{ pointDetail.detail }}-->
      </div>
      <div class="more" @click="seemore" v-if="showText">
        查看更多<i class="iconfont icon-xiala"></i>
      </div>
      <div class="packup" @click="packup" v-else>
        收起<i class="iconfont icon-zhankai"></i>
      </div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      duration: null,
      showText: true,
      longitude: "",
      latitude: "",
    };
  },
  props: {
    pointDetail: {
      type: Object,
      required: true,
    },
  },

  methods: {
    timeFormat(seconds) {
      var m =
          Math.floor(seconds / 60) < 10
              ? "0" + Math.floor(seconds / 60)
              : Math.floor(seconds / 60);
      var s =
          Math.floor(seconds - m * 60) < 10
              ? "0" + Math.floor(seconds - m * 60)
              : Math.floor(seconds - m * 60);
      return m + ":" + s;
    },
    handlePlay() {
      this.$store.commit("changeAudioPlayFlag", true);
      this.$store.commit("changeAudioFlag", true);
      // this.myAudio.play();
      this.countDown(this.duration);
    },
    handlePause() {
      this.$store.commit("changeAudioPlayFlag", false);
      // this.myAudio.pause();
    },
    countDown(seconds) {
      let time = document.querySelector(".time");

      let timer = setInterval(() => {
        seconds--;
        time.innerText = this.timeFormat(seconds);
        if (seconds <= 0 || !this.audioPlayFlag) {
          clearInterval(timer);
          this.duration = seconds;
        }
      }, 1000);
    },
    seemore() {
      let more = document.querySelector(".detail");
      more.style.height = "auto";
      this.showText = false;
    },
    packup() {
      let more = document.querySelector(".detail");
      more.style.height = "32px";
      this.showText = true;
    },
    openGaode() {
      let latitude = this.latitude;
      let longitude = this.longitude;
      console.log(longitude, latitude);
      wx.openLocation({
        latitude: latitude, // 纬度，浮点数，范围为90 ~ -90
        longitude: longitude, // 经度，浮点数，范围为180 ~ -180。
        name: "", // 位置名
        address: "", // 地址详情说明
        scale: 20, // 地图缩放级别,整形值,范围从1~28。默认为最大
        infoUrl: "", // 在查看位置界面底部显示的超链接,可点击跳转
      });
    },

  },
  computed: {
    ...mapState(["audioFlag", "audioPlayFlag"]),
    myAudio() {
      // return document.getElementById("my-audio");
      return this.$refs.myAudio;
    },
  },
  mounted() {
    console.log(this.pointDetail)
    this.$store.commit("changeTitle", this.pointDetail.title);
    this.latitude = this.pointDetail.latitude;
    this.longitude = this.pointDetail.longitude;
    console.log(this.pointDetail, 999);
    this.myAudio.src = this.pointDetail.audiosrc;
    this.$store.commit("changeAudiosrc", this.pointDetail.audiosrc);
    let that = this;
    this.myAudio.ondurationchange = function () {
      that.duration = that.myAudio.duration;
      let time = document.querySelector(".time");
      time.innerText = that.timeFormat(that.duration);
    };
  },

};
</script>

<style scoped lang="less">
.wrapper {
  width: 100%;
  // height: 500px;
  .box1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 30px 20px 20px 20px;
    box-sizing: border-box;
    position: relative;
    border-radius: 5px;
    .img {
      width: 60px;
      height: 60px;
      border-radius: 5px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }
    }
    .i2 {
      .title {
        text-align: center;
        font-size: 14px;
        color: #333;
        font-weight: bold;
      }
      .dis {
        font-size: 12px;
        margin-top: 10px;
        color: #999;
      }
      .btn {
        margin-top: 15px;
        padding: 8px 14px;
        height: 30px;
        background-color: #12dba5;
        border-radius: 15px;
        line-height: 30px;
        text-align: center;
        color: #fff;
        span {
          font-size: 12px;
        }
      }
    }
    .i3 {
      text-align: center;
      .time {
        font-size: 14px;
        color: #a07416;
        margin-top: 5px;
      }
    }
    .i3-c {
      width: 50px;
      height: 50px;
      border: 2px solid #a07416;
      border-radius: 50%;
      overflow: hidden;
      .i3-box {
        width: 100%;
        height: 100%;
        background-color: #f5ebe8;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        .a {
          width: 40px;
          height: 40px;
          background-color: #fff;
          border-radius: 50%;
          text-align: center;
          line-height: 40px;
          color: #a07416;
          i {
            font-size: 20px;
          }
        }
      }
    }
    .num {
      position: absolute;
      left: 0;
      top: 20px;
      width: 16px;
      height: 20px;
      background-color: #a07416;
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
      color: #ffffff;
      text-align: center;
    }
    .la {
      width: 38px;
      height: 4px;
      background-color: #ccc;
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translate(-50%);
      border-radius: 5px;
    }
  }
  .jinqujieshao {
    margin-top: 10px;
    width: 100%;
    background-color: #ffffff;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 5px;
    .title {
      height: 20px;
      line-height: 20px;
      font-weight: bold;

      p {
        height: 16px;
        width: 3px;
        background-color: #12dba5;
        display: inline-block;
      }
    }
    .detail {
      color: #999;
      margin-top: 10px;
      font-size: 12px;
      height: 32px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display:none;
      }
    }
    .more,
    .packup {
      width: 80px;
      height: 33px;
      line-height: 33px;
      background-color: #f5f5f5;
      color: #333;
      font-size: 12px;
      border-radius: 20px;
      margin: 10px auto;
      text-align: center;
    }
  }
}
</style>
