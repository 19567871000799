<template>
  <div class="container-a">
    <!-- 导航栏模块 -->
    <div class="tabs" v-show="tabFlag">
      <tab
          class="tab"
          bar-active-color="#B6851A"
          active-color="#B6851A"
          :line-width="2"
          v-model="tabIndex"
      >
        <tab-item
            v-for="(item, i) in tabList"
            :key="i"
            @on-item-click="changeNav1(item.index2, item.id)"
        >{{ item.label }}
        </tab-item>
      </tab>
      <i class="iconfont icon-caidan" @click="tabFlag = !tabFlag"></i>
    </div>
    <!-- 景点分类 -->
    <div class="tabs2" v-show="tab2Flag">
      <tab
        class="tab"
        bar-active-color="#B6851A"
        active-color="#B6851A"
        :line-width="2"
        v-model="tab2Index"
      >
        <tab-item
          v-for="(item, i) in attractionsType"
          :key="i"
          @on-item-click="changeAttractionsType(item.value)"
        >{{ item.label }}
        </tab-item>
      </tab>
    </div>
    <div class="b-tabs" v-show="!tabFlag">
      <div class="top-tab">
        <p>全部</p>
        <i class="iconfont icon-quxiao" @click="tabFlag = !tabFlag"></i>
      </div>
      <div class="tab-box">
        <div
            class="item"
            :class="tabIndex == i ? 'active' : ''"
            v-for="(item, i) in tabList"
            :key="i"
            @click="changeNav(item.index2, item.id)"
        >
          <!-- <i :class="[item.icon, 'iconfont']"></i> -->
          <p>{{ item.label }}</p>
        </div>
      </div>
    </div>
    <!-- 地图模块 -->
    <div class="amap-wrapper">
      <el-amap
          :plugin="plugin"
          ref="map"
          vid="amap-vue"
          :amap-manager="amapManager"
          :center="center"
          :zoom="zoom"
          :mapStyle="mapStyle"
          class="amap-demo"
          :events="events"
      >
        <!-- 手绘地图 -->
        <el-amap-ground-image
            :url="groundimage.url"
            :bounds="groundimage.bounds"
            :events="groundimage.events"
        ></el-amap-ground-image>
        <!-- 标记点 -->

        <el-amap-marker
            :vid="marker.id"
            v-for="(marker) in markers"
            :key="marker.id"
            :position="marker.position"
            :icon="marker.icon"
            :clickable="true"
            :offset="[-12, -15]"
            :events="marker.events"
            :label="marker.label"
            :extData="marker"
        ></el-amap-marker>
      </el-amap>
    </div>
    <!-- 定位点 -->
    <div class="point" @click="changeNowPoint">
      <i class="iconfont icon-dingweidiantubiao"></i>
    </div>
    <!-- 标记点信息 -->
    <point-detail
        v-if="pointDeatilFlag"
        class="point-detail"
        :pointDetail="pointDetail"
    ></point-detail>

    <!-- 自动导览开关 -->
    <div class="switch-box" style="display: none;">
      <inline-x-switch
          class="switch"
          @on-change="handleSwitch"
          v-model="switchVal"
      ></inline-x-switch>
      <p>自动导览</p>
    </div>
    <!-- 全局音频播放器 -->
    <my-audio class="myaudio" v-if="audioFlag"></my-audio>
    <!-- 回到原点 -->
    <div class="goorigin" v-if="gobackFlag" @click="goOrigin">
      <i class="iconfont icon-fanhui"></i> 返回景区
    </div>
    <!-- 游览路线 -->
    <div class="path" v-show="pathFlag" @click="handlePathClick">
      <i class="iconfont icon-luxian"></i>
      <p>游览路线</p>
    </div>

    <popup :value="popupVal" position="bottom" class="popup" @on-hide="popupVal = false">
      <div class="popupBox" v-for="(item, i) in pathList" :key="i">
        <div class="box1">
          <div class="i1">{{ item.id + '.' + item.title }}</div>
          <div class="i2">
            <p v-for="(item1, i) in item.tags" :key="i">{{ item1 }}</p>
          </div>
        </div>
        <router-link
            tag="div"
            :to="{ name: 'openPathDetail', params: { id: item.id } }"
            class="box2"
        >
          开始游览
        </router-link>
      </div>
    </popup>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import { mapState } from 'vuex'
import comparens from '../utils/compareNS.js'
import addImgSrc from '../utils/addImgSrc.js'
import { Tab, TabItem, Popup, InlineXSwitch } from 'vux'
import VueAMap from 'vue-amap'
import PointDeatil from '../components/PointDetail.vue'
import myAudio from '../components/MyAudio'
// import wx from "weixin-js-sdk";
import Cookies from 'js-cookie'

// import { getTextByteLen } from '@/utils/getTextByteLen'
let amapManager = new VueAMap.AMapManager()

export default {
  components: {
    Tab,
    TabItem,
    Popup,
    InlineXSwitch,
    pointDetail: PointDeatil,
    myAudio,
  },
  data () {
    let self = this
    return {
      attractionsType: [],
      amapManager, // 地图实例
      isShowAd: true, // 是否显示进场广告弹窗
      id2: 1,
      zoom: 11,
      center: [112.142597,38.386603], // 地图中心点
      origin: [112.142597,38.386603], // 手绘地图原点
      // 地图的事件
      events: {
        click: (e) => {
          this.handleMapClick(e)
        },
        // complete: () => {
        //   this.$refs.cloud.style.display = 'block'
        // }
      },
      // 手绘地图图层信息
      groundimage: {
        url: require('@/assets/images/qy.png'),
        bounds: [
          // [112.200686, 37.653337], //左下角
          // [112.600734, 38.022378] //右上角
          [111.704041,38.138361], //左下角
          [112.325487,38.694969] //右上角
        ],
        events: {
          init () {
          },
          click () {
            alert('click groundimage')
          },
        },
      },
      //回到当前定位点
      plugin: [
        {
          timeout: 100, //超过10秒后停止定位，默认：无穷大
          panToLocation: false, //定位成功后将定位到的位置作为地图中心点，默认：true
          zoomToAccuracy: false, //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：f
          pName: 'Geolocation',
          showButton: false,
          events: {
            click () {},
            init (o) {
              // o 是高德地图定位插件实例
              if (!navigator.geolocation) {
                alert('不能获取到位置')
              }
              o.getCurrentPosition((status, result) => {
                console.log(status, result)
                if (result || result.position) {
                  self.address = result.formattedAddress
                  self.lng = result.position.lng
                  self.lat = result.position.lat
                  // self.center = [self.lng, self.lat];
                  self.loaded = true
                  self.$nextTick()
                }
              })
            },
          },
        },
      ],
      mapStyle: 'fresh',
      // 点标记信息
      markers: [],
      // 景区区域
      // mypoints: "112.560964||37.87299||112.568217||37.878631",
      mypoints: [
        { lat: 37.903623, lng: 112.453804 },
        { lat: 37.77999, lng: 112.726403 },
      ],
      // 导航栏在第几个
      tabIndex: 0,
      // 默认选中哪一项景点分类
      tab2Index: -1,
      //导航栏信息
      tabList: [],
      tab2List: [],
      // 切换导航栏
      tabFlag: true,
      tab2Flag: true,
      // 点标记详情信息
      pointDetail: {},
      // 决定标记点详情的显示与否
      pointDeatilFlag: false,
      //决定返回景区的显示与否
      gobackFlag: false,
      popupVal: false,
      pathFlag: true,
      //游览路线列表
      pathList: [],
      lng: '',
      lat: '',
      switchVal: false,
      intervalTimer: null,
    }
  },
  methods: {
    // 点击开始导览关闭广告
    closeAd() {
      this.isShowAd = false
      Cookies.set('isShowAd', '1', { expires: 1 })
    },
    // 点击获取景区分类
    async getAttractionsType() {
      const { data: res } = await this.$axios.get('/open/backend/attractions/getAttractionsAsign')
      this.attractionsType = []
      if (res.code === 200) {
        console.log(res.data)
        res.data.forEach((item) => {
          console.log(item, '@')
          this.attractionsType.push({
            label: item.dictLabel,
            value: item.dictValue
          })
        })
      }
    },
    // 切换景点分类
    async changeAttractionsType(value) {
      const { data: res } = await this.$axios.get(`/open/backend/attractions/all?aSign=${value}`)
      console.log(res, '@@@@@')
      this.markers = []
      if (res.code === 200) {
        console.log(res.data, '@391')
        res.data.forEach((item) => {
          console.log(item)
          this.markers.push({
            id: item.id,
            label: {
              content: item.name,
              offset: [-(0.5 * (item.name.length * 10)), 30]
            },
            position: [item.longitude, item.latitude],
            icon: addImgSrc('/jingdianicon.png'),
            events: {
              click: (e) => {
                this.handleMarker(e)
              }
            }
          })
        })
      }
    },
    /**
     * 切换展开导航的函数
     */
    changeNav (index2, i) {
      this.pointDeatilFlag = false
      this.tabIndex = index2 + 1
      this.tab2Index = -1
      if (i === 1) {
        this.tabIndex = 0
      }
      this.id2 = i
      this.tabFlag = true
      this.pathFlag = i === 1
      // this.zoom = zoom
      this.getAddressInfo(i)
    },
    /**
     * 切换单列导航的函数
     */
    changeNav1 (index2, i) {
      this.pointDeatilFlag = false
      // this.zoom = zoom
      this.tabIndex = index2 + 1
      this.tab2Index = -1
      if (i === 1) {
        this.tabIndex = 0
      }
      this.pathFlag = i === 1
      this.id2 = i
      this.getAddressInfo(i)
    },
    /**
     * 点击定位点的函数
     */
    changeNowPoint () {
      this.center = [this.lng, this.lat]
      this.gobackFlag = true
      this.pathFlag = false
    },
    // 点击标记点的事件
    handleMarker (e) {
      const data = e.target.w.extData
      console.log(data, 5555)

      // this.pointDeatilFlag = true;

      // if (this.pointDeatilFlag) {
      this.getPointDetail(data.id)

      this.center = data.position
      // }
      // for (let i = 0; i < this.markers.length; i++) {
      //   if (!Object.is(data.id, this.markers[i].id)) {
      //     this.markers[i].icon = this.markers[i].icon2
      //   }
      // }
    },
    // 点击地图的事件
    handleMapClick () {
      this.pointDeatilFlag = false
    },
    /**
     * 回到原点的函数
     */
    goOrigin () {
      this.center = this.origin
      this.gobackFlag = false
      this.pathFlag = true
    },
    /**
     * 点击游览路线的函数
     */
    handlePathClick () {
      this.popupVal = !this.popupVal
      this.getPathList()
    },
    /**
     * 打开游览路线详情
     */
    openPathDetail () {},
    /**
     * 获取所有的tab列表
     */
    async getAllPointList () {
      const { data: res } = await this.$axios.get('/open/backend/pointType/all')
      if (res.code === 200) {
        let arr = []
        res.data.forEach((item, index) => {
          let obj = {}
          obj.id = +item.id
          obj.index2 = index
          obj.label = item.name
          obj.icon = 'https://zhsq.jlxrmtzx.com:3012/zhly' + item.icon
          obj.zoom = +item.zoom
          arr.push(obj)
        })
        this.tabList = arr
        let one = {
          id: 1,
          label: '景点'
        }
        this.tabList = [one, ...this.tabList]
      }
    },
    /**
     * 点击导航，获取多个点的位置信息
     */
    async getAddressInfo (id) {
      if (id === 1) {
        var { data: res } = await this.$axios.get('/open/backend/attractions/all')
        await this.getAttractionsType()
      } else {
        // eslint-disable-next-line no-redeclare
        var { data: res } = await this.$axios.get('/open/backend/point/all?type=' + id)
      }
      if (res.code == 200) {
        let arr = []
        console.log(res.data, '@391')
        res.data.forEach((item) => {
          let obj = {}
          obj.id = item.id
          obj.label = {
            content: item.name,
            offset: [-(0.5 * (item.name.length * 10)), 30]
          }
          obj.position = [item.longitude, item.latitude]
          // 自己加个标点
          console.log(this.tabList)
          obj.icon = id === 1 ? addImgSrc('/jingdianicon.png') : this.tabList[this.tabIndex].icon
          // obj.icon = addImgSrc(item.icon);
          // obj.icon1 = addImgSrc(item.icon1);
          // obj.icon2 = addImgSrc(item.icon);
          obj.events = {
            click: (e) => {
              this.handleMarker(e)
            },
          }
          if(item.id === 29) {
            obj.label = ''
          }
          if(item.id === 45) {
            obj.label = ''
          }

          arr.push(obj)
        })
        // console.log(arr)
        this.markers = arr
      }
    },
    /**
     * 获取单个点的具体信息
     */
    async getPointDetail (id) {
      this.pointDeatilFlag = false
      console.log(this.id2)
      if (this.id2 === 1) {
        var { data: res } = await this.$axios.get('/open/backend/attractions/' + id)
      } else {
        // eslint-disable-next-line no-redeclare
        var { data: res } = await this.$axios.get('/open/backend/point/' + id)
      }
      console.log(res, 444444)
      if (res.code === 200) {
        console.log(res.data)
        let obj = {}
        obj.id = res.data.id
        console.log(this.tabList[this.tabIndex].icon)
        obj.icon = this.tabIndex === 0 ? addImgSrc('/20210123055759036.dwei (1).png') : this.tabList[this.tabIndex].icon
        obj.detail = res.data.descr
        obj.title = res.data.name
        obj.imgsrc = 'https://zhsq.jlxrmtzx.com:3012/zhly' + res.data.topImage
        if (res.data.audio) {
          obj.audiosrc = 'https://zhsq.jlxrmtzx.com:3012/zhly' + res.data.audio
        } else {
          obj.audiosrc = res.data.audio
        }
        obj.longitude = res.data.longitude
        obj.latitude = res.data.latitude
        obj.lng = this.lng
        obj.lat = this.lat
        this.pointDetail = obj
      }
    },
    /**
     * 获取路线列表信息
     */
    async getPathList () {
      const { data: res } = await this.$axios.get('/open/backend/route/all')
      if (res.code === 200) {
        let arr = []
        res.data.forEach(item => {
          let obj = {}
          obj.id = item.id
          obj.title = item.routeName
          obj.spotNum = item.scenicCount + '个景点'
          obj.time = (item.travelTime / 60).toFixed(1) + '小时'
          obj.distance = item.routeLength + '公里'
          obj.imgsrc = addImgSrc(item.image)
          obj.tags = item.tags
          arr.push(obj)
        })
        this.pathList = arr
      }
    },
    handleSwitch (currentValue) {
      console.log(currentValue)
      if (currentValue) {
        console.log(this.lat, this.lng)
        let flag = comparens(this.mypoints, { lat: this.lat, lng: this.lng })
        if (flag) {
          this.$notify({ type: 'warning', message: '当前不在景区内' })
          this.$nextTick(() => {
            this.switchVal = false
          })
        } else {
          this.$notify({ type: 'success', message: '自动导览已开启' })
          this.$store.commit('changeAudioType', '2')
          this.startLocation()
        }
      }
      if (!currentValue && this.audioType == '2') {
        clearInterval(this.intervalTimer)
        this.$store.commit('changeAudioType', '1')
        this.$store.commit('changeAudiosrc', '')
        this.$notify({ type: 'primary', message: '自动导览已关闭' })
      }
    },
    // 获取位置信息的接口
    startLocation () {
      let that = this
      wx.getLocation({
        type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: function (res) {
          if (that.audioType === '2') {
            that.getPlayList(res)
          }
          that.intervalTimer = setInterval(() => {
            if (that.audioType === '2') {
              console.log('再一次获取地理位置，发送请求')
              that.startLocation()
            }
          }, 10000)
        },
      })
    },
    async getPlayList (location) {
      let latitude = location.latitude
      let longitude = location.longitude
      const { data: res } = await this.$axios.get(
          `/open/amapPoint/nearest?latitude=${latitude}&longitude=${longitude}`
      )
      if (res.data.length !== 0) {
        let audiosrc = 'https://zhsq.jlxrmtzx.com:3012/zhly' + res.data.audio
        this.$nextTick(() => {
          this.$store.commit('changeAudiosrc', audiosrc)
          this.$store.commit('changeAudioFlag', true)
          this.$store.commit('changeTitle', res.data.name)
        })
      }
      console.log(res)
    },

    async getAccessToken () {
      let url = location.href.split('#')[0]
      // let url = 'https://jlzhcszhsq.chwhyun.com/';
      const { data: res } = await this.$axios.post(
          `open/wx/getSignature?url=${url}`
      )
      if (res.code == 200) {
        console.log(res.data, '!!!')
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.appid, // 必填，公众号的唯一标识
            timestamp: +res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.noncestr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名
            jsApiList: [
              "getLocation",
              "openLocation",
              "onVoicePlayEnd",
              "playVoice",
              "pauseVoice",
              "stopVoice",
              "checkJsApi",
            ], // 必填，需要使用的JS接口列表
          });

        wx.ready(function () {
          // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
          wx.checkJsApi({
            jsApiList: ["openLocation"], // 需要检测的JS接口列表，所有JS接口列表见附录2,
            success: function (res) {
              console.log(res);
              // 以键值对的形式返回，可用的api值true，不可用为false
              // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
            },
          });
          // wx.getLocation({
          //   type: "wgs84", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
          //   success: function (res) {

          //   },
          // });
        });
      }
    },
  },
  created () {
    this.getAccessToken()
    this.getAllPointList()
    this.getAddressInfo(1)
  },
  mounted () {
    this.isShowAd = !Cookies.get('isShowAd');
  },
  beforeDestroy () {
    this.$nextTick(() => {
      this.pointDeatilFlag = false
    })
  },
  watch: {
    pointDetail: {
      handler (newVal) {
        this.pointDetail = newVal
        this.pointDeatilFlag = true
      },
      deep: true,
    },
    tabIndex(newVal) {
      newVal === 0 && this.getAttractionsType()
      this.tab2Flag = newVal === 0;
    }
  },
  computed: {
    ...mapState(['audiosrc', 'audioFlag', 'audioPlayFlag', 'audioType']),
  },
}
</script>

<style scoped lang="less">
/*
@-webkit-keyframes adAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes adAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
*/

@-webkit-keyframes cloudToLeftDown {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  100% {
    transform: translate3d(-200px, 100vh, 0);
  }
}
@keyframes cloudToLeftDown {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  100% {
    transform: translate3d(-200px, 100vh, 0);
  }
}
@-webkit-keyframes cloudToRightTop {
  0% {
    transform: translate3d(-100%, 50vh, 0) rotate(10deg);
  }
  100% {
    transform: translate3d(100px, 0, 0) rotate(10deg);
  }
}
@keyframes cloudToRightTop {
  0% {
    transform: translate3d(-100%, 50vh, 0) rotate(10deg);
  }
  100% {
    transform: translate3d(100px, 0, 0) rotate(10deg);
  }
}
@-webkit-keyframes cloudToRight {
  0% {
    transform: translate3d(0, 60vh, 0) rotate(180deg);
  }
  100% {
    transform: translate3d(100vw, 0, 0) rotate(160deg);
  }
}
@keyframes cloudToRight {
  0% {
    transform: translate3d(0, 60vh, 0) rotate(180deg);
  }
  100% {
    transform: translate3d(100vw, 0, 0) rotate(160deg);
  }
}

@-webkit-keyframes birdFly {
  0% {
    opacity: 1;
    transform: translate3d(100%, 0, 0);
  }
  40% {
    opacity: 1;
    transform: translate3d(-100vw, 500px, 0);
  }
  50% {
    opacity: 0;
    transform: translate3d(-100vw, 0, 0);
  }
  51% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(100vw, 100vh, 0);
  }
}

@keyframes birdFly {
  0% {
    opacity: 1;
    transform: translate3d(100%, 0, 0);
  }
  40% {
    opacity: 1;
    transform: translate3d(-100vw, 500px, 0);
  }
  50% {
    opacity: 0;
    transform: translate3d(-100vw, 0, 0);
  }
  51% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(100vw, 100vh, 0);
  }
}
.container-a {
  width: 100vw;
  height: 100vh;
}
.popup {
  max-height: 60% !important;
}
.amap-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
}
.alertBox {
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  overflow: hidden;
  z-index: 9999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    &:first-child {
      width: 70vw;
      // -webkit-animation: adAnimation 10s linear infinite;
      // animation: adAnimation 10s linear infinite;
    }
    &:nth-child(2) {
      width: 40vw;
    }
    &:last-child {
      margin-top: 20px;
      width: 30vw;
    }
  }
}
.cloud {
  position: absolute;
  left: 40%;
  top: 0;
  z-index: 99999;
  pointer-events: none;
  transform: translate3d(0, -100%, 0);
  &.toright {
    width: 28%;
    -webkit-animation: cloudToRight 30s linear infinite;
    animation: cloudToRight 30s linear infinite;
  }
  &.toleftdown {
    width: 40%;
    -webkit-animation: cloudToLeftDown 50s linear infinite;
    animation: cloudToLeftDown 50s linear infinite;
  }
  &.torighttop {
    width: 50%;
    -webkit-animation: cloudToRightTop 60s linear infinite;
    animation: cloudToRightTop 60s linear infinite;
  }
}
.bird {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99999;
  width: 40%;
  pointer-events: none;
  transform: translate3d(100%, 0, 0);
  -webkit-animation: birdFly 40s linear infinite;
  animation: birdFly 40s linear infinite;
}
.switch-box {
  position: absolute;
  right: 15px;
  top: 130px;
  width: 50px;
  height: 50px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;

  p {
    font-size: 12px;
    margin-top: 5px;
    color: rgb(94, 94, 94);
  }
}
.tabs {
  top: 0;
  .tab {
    width: 88%;
  }
}
.tabs2 {
  top: 8vh;
  .tab {
    width: 100%;
  }
}
.tabs, .tabs2 {
  position: fixed;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 8vh;
  background-color: #fff;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  .tab {
    height: 8vh;
  }

  i {
    width: 10%;
    height: 100%;
    line-height: 7vh;
    text-align: center;
    font-size: 20px;
  }
}

.b-tabs {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  z-index: 99;

  .top-tab {
    width: 100%;
    height: 8vh;
    display: flex;
    justify-content: space-between;
    line-height: 6vh;

    p,
    i {
      width: 12%;
      text-align: center;
    }
  }

  .tab-box {
    margin-top: 2vh;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;

    .item {
      width: 25%;
      height: 25vw;
      box-sizing: border-box;
      text-align: center;
      border-right: 1px solid #f7f7f7;
      border-top: 1px solid #f7f7f7;
      padding-top: 7vw;
      color: #666;
      font-size: 14px;
    }

    .item.active {
      color: #b6851a;
    }
  }
}

.point {
  position: fixed;
  left: 20px;
  bottom: 60px;
  z-index: 10;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;

  i {
    font-size: 20px;
  }
}

.point-detail {
  position: absolute;
  width: 95vw;
  left: 50%;
  transform: translate(-50%);
  /* top: 60vh;*/
  bottom: 10px;
  z-index: 99;
  border-radius: 15px;
}

.goorigin {
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  bottom: 100px;
  width: 30%;
  height: 40px;
  background-color: #12dba5;
  border-radius: 30px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  font-weight: bold;
  font-size: 14px;

  i {
    font-size: 18px;
  }
}

.path {
  width: 65px;
  height: 65px;
  background-color: #12dba5;
  border-radius: 50%;
  border: 2px solid #fff;
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  bottom: 50px;
  color: #fff;
  text-align: center;

  i {
    font-size: 25px;
    margin-top: 5px;
    display: inline-block;
  }

  p {
    white-space: pre-wrap;
    font-size: 12px;
  }
}

.popupBox {
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  .box1 {
    flex: 1;
    .i1 {
      font-size: 14px;
      color: #333;
    }

    .i2 {
      width: 65vw;
      display: flex;
      margin-top: 10px;
      flex-wrap: wrap;

      p {
        border: 1px solid #666;
        color: #666;
        font-size: 12px;
        margin-right: 15px;
        margin-top: 10px;
        padding: 3px 4px;
        border-radius: 10px;
      }
    }
  }

  .box2 {
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #b6851a;
    font-size: 12px;
    text-align: center;
    line-height: 50px;
    white-space: nowrap;
    color: #b6851a;
  }

  .amap-logo {
    display: none !important;
    z-index: -1;
  }
}

.myaudio {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 16vh;
  width: 80%;
  height: 60px;
  background-color: #f1f3f4;
}
</style>

<style>
.amap-logo {
  display: none !important;
  z-index: -1;
}

.inline-x-switch.weui-switch.switch {
  width: 40px;
  height: 21px;
  margin-top: 5px;
}

.inline-x-switch.weui-switch.switch::before {
  content: "";
  width: 20px;
  height: 20px;
}

.inline-x-switch.weui-switch.switch::after {
  content: "";
  width: 20px;
  height: 20px;
}
</style>
<style>
.amap-icon img {
  width: 24px;
  height: 30px;
  /* //width: 16px;
  //height: 20px; */
}
.container-a .amap-marker-label {
  font-size: 12px;
  color: #000;
  border: 2px solid #265443;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background-color: #cdd9cb;
}
</style>
