<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  beforeDestroy () {
    this.$nextTick(() => {
      this.$store.commit('changeAudiosrc', '')
      this.$store.commit('changeAudioFlag', false)
      this.$store.commit('changeAudioPlayFlag', false)
    })
  }
}
</script>

<style>
#app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
</style>
