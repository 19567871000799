import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    audiosrc:'',
    audioFlag:false,
    audioPlayFlag:false,
    pointDetail:{},
    defaultIndex: null,
    title:'',
    audioType:'1'
  },
  mutations: {
    changeAudiosrc (state,payload) {
      state.audiosrc = payload
      state.audioFlag = false
      state.audioPlayFlag = false
    },
    changeAudioFlag (state,payload) {
      state.audioFlag = payload
    },
    changeAudioPlayFlag (state,payload) {
      state.audioPlayFlag = payload
    },
    changeTitle (state,payload) {
      state.title = payload
    },
    changeAudioType(state,payload) {
      state.audioType = payload
    },
    changePointDetail (state,payload) {
      state.pointDetail = payload
    },
    changeDefaultIndex (state,payload) {
      state.defaultIndex = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
