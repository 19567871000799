import Vue from "vue";
import axios from "axios";
// axios.defaults.baseURL = "http://192.168.1.138:8091/";

const server = axios.create();
server.defaults.baseURL = "https://zhly.jlxrmtzx.com:3012/";

const server2 = axios.create();
//f服务器
server2.defaults.baseURL = "https://zhly.jlxrmtzx.com:3012/";
//本地
// server2.defaults.baseURL = "http://192.168.1.44:8080";
const server3 = axios.create();
server3.defaults.baseURL = "https://zhly.jlxrmtzx.com:3012/";
//server3.defaults.baseURL = "http://192.168.1.105:8088";
//导览H5使用
Vue.prototype.$axios = server;
//旅游景区和游客二维码使用
Vue.prototype.$axios2 = server2;
//社区疫情防控二维码表单使用
Vue.prototype.$axios3 = server3;